<script>
    import {useNavigate} from 'svelte-navigator';
    import {onMount} from 'svelte';
    import {Button, Card, Input, Modal} from 'svelte-chota';
    import {_, isLoading, locale, locales} from 'svelte-i18n';
    import Loader from './lib/Loader.svelte';
    import {game_name, login_route} from './constants';
    import {logMetric} from './metrics';
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faGlobe, faXmark} from '@fortawesome/free-solid-svg-icons';
    import {user} from './stores';

    const navigate = useNavigate();

    let username;
    let password = '';
    let loading;
    let login_details_info;
    let remember_player_name = false;
    let remember_check_box;

    let modal_hide = () => {
        login_details_info = false;
    };

    let loginResponse = {
        error: null,
        user: null,
    };

    locale.subscribe((data) => {
        if (data === null) return;

        logMetric({language: data});
    });

    onMount(() => {
        username = localStorage.player_name ? localStorage.getItem('player_name') : '';
        remember_player_name = localStorage.player_name ? true : false;
    });

    const handleSubmit = () => {
        let loginFields = {
            username,
            password,
        };
        loading = true;
        loginResponse = {
            error: null,
            user: null,
        };
        fetch(login_route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginFields),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode) {
                    loginResponse = {
                        ...loginResponse,
                        error: data.message,
                    };
                } else {
                    loginResponse = {
                        ...loginResponse,
                        user: data.user,
                    };
                    localStorage.removeItem('user');
                    localStorage.removeItem('player_name');
                    loginResponse.user.username = username;
                    user.set(loginResponse);
                    if (remember_player_name) {
                        localStorage.setItem('player_name', username);
                    }
                    localStorage.setItem('user', JSON.stringify(loginResponse));

                    Rollbar.configure({
                        payload: {
                            person: {
                                id: data.user.id, // required
                                username: username,
                            }
                        }
                    });
                    
                    navigate('/shop');
                }
            })
            .catch((error) => {
                Rollbar.critical(error);
            })
            .finally(() => {
                logMetric({
                    login: {
                        username: username,
                        ...(loginResponse.error !== null && {error: loginResponse.error})
                    },
                });
                loading = false;
            });
    };
</script>
<div class="user-select">
    <Fa fw icon={faGlobe} primaryColor="#96e3ff"/>
    <select bind:value={$locale} class="selector">
        {#each $locales as locale}
            <option value={locale}>{locale.toUpperCase()}</option>
        {/each}
    </select>
</div>
<div class="login">
    {#if $isLoading}
        <Loader/>
    {:else}
        <div class="login">
            <a href="#home" on:click={() => navigate(-1)}><img src="images/logo.png" class="logo"
                                                               alt={game_name}
                                                               title={game_name}/></a>
            <div class="form">
                <h1>{$_('Login')}</h1>
                {#if loginResponse.error}
                    <div class="error">
                        {loginResponse.error}
                    </div>
                {/if}
                <form
                        on:submit|preventDefault={handleSubmit}
                        autocomplete="on"
                        class="flex flex-column"
                >
                    <p class="login-name">
                        <Input
                                id="username"
                                name="username"
                                bind:value={username}
                                placeholder="Player Name"
                                class="input-field"
                                autocorrect="off"
                                autocapitalize="none"
                                autocomplete="username"
                                type="search"
                                required
                        />
                    </p>
                    <p class="login-password">
                        <Input
                                id="password"
                                name="password"
                                bind:value={password}
                                placeholder="Token"
                                class="input-field"
                                autocorrect="off"
                                autocapitalize="none"
                                autocomplete="off"
                                type="search"
                                required
                        />
                    </p>
                    <label>
                        <input type=checkbox bind:checked={remember_player_name}>
                        Remember player name
                    </label>

                    <!-- svelte-ignore a11y-invalid-attribute -->
                    <a
                            on:click={() => (login_details_info = true)}
                            class="login-info"
                            href="javascript:">How to find your login details?</a
                    >
                    <Button submit class="blue" {loading}>
                        <img class="snow3" src="/images/snow2.png" alt=""/>
                        {$_('AccountSignIn')}</Button>
                    <small>
                        {@html $_('acceptPPandToS', {
                            values: {
                                ToS:
                                    '<a target="_blank" href="/terms-of-service">' +
                                    $_('TermsOfService') +
                                    '</a>',
                                PP:
                                    '<a target="_blank" href="/privacy-policy">' +
                                    $_('PrivacyPolicy') +
                                    '</a>',
                            },
                        })}
                    </small>
                </form>
            </div>
            <Modal bind:open={login_details_info}>
                <Card class="howto">
                    <!-- svelte-ignore a11y-invalid-attribute -->
                    <a href="javascript:" on:click={modal_hide} class="close-button">
                        <Fa icon={faXmark} fw size="2x" primaryColor="#240c05"/>
                    </a>
                    <img
                            src="images/guide.gif"
                            class="logo twoFactor"
                            alt={game_name}
                            title={game_name}
                    />
                    <p>
                        You can find and copy your authentication token in your Player Profile
                        > Account section.
                    </p>
                    <Button submit class="blue" on:click={modal_hide} {loading}
                    >{$_('Close')}</Button
                    >
                </Card>
            </Modal>
        </div>
    {/if}
</div>
<style lang="scss">
  @import './styles/vars.scss';

  :global {
    .howto {
      position: relative;

      p {
        font-weight: bold;
      }

      .close-button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 7px 2px;
        margin: 0;
      }

      .twoFactor {
        max-height: 400px;
      }
    }

    [data-pincode] {
      display: flex;
      border: none;
      // gap: 5px;
      margin-bottom: 10px;

      input {
        width: 3rem;
        padding: 0.5rem 1rem;
        margin: 0;
        border-radius: 0 !important;
        text-align: center;
        font-family: var(--font-family-mono) !important;
        transition: all 0.2s ease-in-out;
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        background-color: white;
        background-image: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0.02) 0%,
                        rgba(255, 255, 255, 0.02) 50%
        );
        box-shadow: inset 0 1px 2px rgb(0 0 0 / 20%);

        &:first-child {
          border-radius: 4px 0 0 4px !important;
        }

        &:last-child {
          border-radius: 0 4px 4px 0 !important;
        }

        &:hover {
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2),
          0 0 5px var(--color-primary) !important;
          z-index: 2;
        }

        &:focus {
          outline: 3px solid var(--color-primary) !important;
          box-shadow: 0 0 5px var(--color-primary) !important;
          z-index: 1;
        }

        &:not(:last-of-type) {
          border-right: 1px solid #e0e0e0;
        }
      }
    }

    .login-name, .login-password {
      color: #000000 !important;
    }

    .input-field {
      margin-bottom: 10px;
      font-family: var(--font-family-mono) !important;
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2),
        0 0 5px var(--color-primary) !important;
      }

      &:focus {
        outline: 3px solid var(--color-primary) !important;
        box-shadow: 0 0 5px var(--color-primary) !important;
      }
    }

    .button {
      margin-top: 10px;
      width: 100%;
    }

    .button.blue {
      padding: 1.4rem 2.5rem;
      color: $color-dark-text;
      background: rgb(67, 255, 255) radial-gradient(
                      ellipse at center,
                      #43ffff 10%,
                      #2182cb 100%
      );
    }

    .user-select {
      position: absolute;
      top: 10px;
      right: 10px;
      border: 1px solid $light-color;
      border-radius: 5px;
      opacity: 0.6;
      transition: all 0.2s ease-in-out;
      z-index: 2;

      a {
        display: block;
        text-align: center;
        height: 30px;
        width: 30px;

        .svelte-fa {
          position: absolute;
          left: 5px;
          top: 7px;
          z-index: 1;
        }
      }

      &:hover {
        opacity: 1;
        box-shadow: 0 0 5px var(--color-primary) !important;
      }

      .selector {
        height: 30px;
        min-width: 70px;
        display: inline-block;
        margin-left: 5px;
        border: none;
        // background-color: transparent;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        color: $light-color;
        padding: 0 30px 0 25px;
        appearance: none;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuNCIgaGVpZ2h0PSIyOTIuNCI+PHBhdGggZmlsbD0iI0ZGRDcwMCIgZD0iTTI4NyA2OS40YTE3LjYgMTcuNiAwIDAgMC0xMy01LjRIMTguNGMtNSAwLTkuMyAxLjgtMTIuOSA1LjRBMTcuNiAxNy42IDAgMCAwIDAgODIuMmMwIDUgMS44IDkuMyA1LjQgMTIuOWwxMjggMTI3LjljMy42IDMuNiA3LjggNS40IDEyLjggNS40czkuMi0xLjggMTIuOC01LjRMMjg3IDk1YzMuNS0zLjUgNS40LTcuOCA1LjQtMTIuOCAwLTUtMS45LTkuMi01LjUtMTIuOHoiLz48L3N2Zz4=');
        background-repeat: no-repeat, repeat;
        background-position: right -1em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
      }

      .svelte-fa {
        position: absolute;
        left: 5px;
        top: 7px;
        z-index: 1;
      }
    }
  }

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    // height: 80vh;
    position: relative;

    label {
      text-align: left;
      display: flex;
      align-items: center;
      font-size: 12px;

      input {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    small {
      font-size: 0.7em;
      color: #fff;
      line-height: 1.4em;
      display: block;
      opacity: 0.6;
      padding: 0 15px;
      text-align: center;
      margin-top: 10px;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }

    .error {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      background: rgb(221 26 0 / 62%);
      border-radius: 4px;
      padding: 4px 8px;
      color: #fffce9;
      border: 2px solid #ca2000;
      text-shadow: 0 0 1px #000;
      text-transform: capitalize;
      box-shadow: 0 0 20px #ff1e0285;
    }

    .login-info {
      margin-top: 15px;
      display: block;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
      border-top: 1px dotted hsl(0deg 0% 100% / 20%);
      padding-top: 5px;
    }

    h1 {
      color: #ffb900;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    .form {
      width: 300px;
      margin-top: -50px;
    }

    .logo {
      max-width: 350px;
      min-height: 299px;
    }
  }
</style>
