<script>
    import {_, number} from "svelte-i18n";
    import giftIcon from '../../assets/gift_friend/gift_icon.png?format=webp;jpg';
    import giftPackIcon from '../../assets/gift_friend/gift_pack_icon.png?format=webp;jpg';
    import giftPackWinterIcon from '../../assets/gift_friend/winter_gift_pack_icon.png?format=webp;jpg';
    import giftBackground from '../../assets/gift_friend/gift_friend_background.png?format=webp;jpg';
    import greenCheckmark from '../../assets/gold_rush/green_checkmark.png?format=webp;jpg';
    import Breadcrumbs from "../Breadcrumbs.svelte";
    import GiftPlayerSearchBox from "../GiftPlayerSearchBox.svelte";
    import PackListing from "../PackListing.svelte";
    import {Button, Modal} from "svelte-chota";
    import SendingTo from "../SendingTo.svelte";
    import {onMount, onDestroy} from 'svelte';
    import {giftFriend, selectedCurrency, castleLevel} from '../../stores';
    import GiftFriendModal from "../modals/GiftFriendModal.svelte";
    import {logMetric} from "../../metrics.js";

    let giftIconUrl = giftIcon;
    let giftPackIconUrl = giftPackIcon;
    let giftPackWinterIconUrl = giftPackWinterIcon;
    let giftBackgroundUrl = giftBackground;
    let greenCheckmarkImage = greenCheckmark;

    let crumbs = [
        {
            text: $_("GiftFriendRecipient"),
            url: "#recipient",
            selected: true,
            canClick: true,
        },
        {
            text: $_("GiftFriendPackSelection"),
            url: "#pack-selection",
            selected: false,
            canClick: false,
        },
        {
            text: $_("GiftFriendGiftNote"),
            url: "#gift-note",
            selected: false,
            canClick: false,
        }
    ];

    let players = [];

    // For Testing purposes
    // let players = [
    //     {
    //         id: 6578111235473,
    //         name: "Bubustiuc",
    //         allianceAcronym: "AGP",
    //         countryCode: "ro",
    //         power: 230000,
    //         castleLevel: 25,
    //     },
    //     {
    //         id: 6578111235474,
    //         name: "Bubustiucul",
    //         allianceAcronym: "SNK",
    //         countryCode: "ro",
    //         power: 230000543,
    //         castleLevel: 5,
    //     },
    //     {
    //         id: 6578111235475,
    //         name: "Bubustinkie",
    //         countryCode: "ro",
    //         power: 1652,
    //         castleLevel: 2,
    //     },
    //     {
    //         id: 6578111235476,
    //         name: "Bubuskill",
    //         countryCode: "ro",
    //         power: 1760000543,
    //         castleLevel: 3,
    //     },
    //     {
    //         id: 6578111235477,
    //         name: "Bubusegnor",
    //         allianceAcronym: "XXX",
    //         countryCode: "ro",
    //         power: 69315054,
    //         castleLevel: 22,
    //     },
    // ]

    let hashValue = 'recipient';
    let player = {};
    let giftMessage = $_("GiftFriendDefaultMessage");

    function handleHashChange(event) {
        hashValue = event.detail;
        crumbs = crumbs.map(crumb => {
            return {...crumb, selected: crumb.url === '#' + hashValue, canClick: crumb.canClick};
        });
    }

    function handlePlayerSelection(event) {
        player = event.detail;
        setLocationHash('pack-selection');
        return false;
    }

    function setLocationHash(hash) {
        window.location.hash = hash;
        window.scrollTo(0, 0);
        let crumb = crumbs.find(e => {
            return e.url === '#' + hash;
        });
        crumb.canClick = true;
        handleHashChange({detail: hash});
    }

    function resetGiftFriend() {
        player = {};
        crumbs.forEach(crumb => {
            crumb.canClick = false;
        })
        setLocationHash('recipient');
    }

    function resetPack() {
        crumbs.forEach(crumb => {
            if (crumb.url !== '#recipient') {
                crumb.canClick = false;
            }
        })
        setLocationHash('pack-selection');
        logMetric({button: 'gift-friend-change-pack'});
    }

    function continuePayment() {
        if (giftMessage === '') {
            giftMessage = $_("GiftFriendDefaultMessage");
        }

        giftFriend.set({
            startPurchase: true,
            selectedPack: $giftFriend.selectedPack,
            selectedPackDetails: $giftFriend.selectedPackDetails,
            message: giftMessage,
        });
        logMetric({button: 'gift-friend-continue-payment', message: giftMessage});
    }

    let vignetteElement;

    onMount(() => {
        vignetteElement = document.querySelector('.vignette');
        if (vignetteElement) {
            vignetteElement.classList.add('hide-background');
        }
    });

    onDestroy(() => {
        if (vignetteElement) {
            vignetteElement.classList.remove('hide-background');
        }
    });

    giftFriend.subscribe(value => {
        if (!value.startPurchase && hashValue === 'gift-note') {
            resetGiftFriend();
        }

        if (value.selectedPack && hashValue === 'pack-selection') {
            setLocationHash('gift-note');
            logMetric({button: 'gift-friend-select-pack'});
        }
    });

    function redirectToDailyDeals() {
        // Select all the span elements within the element with class "tabs"
        let spans = document.querySelectorAll('.tabs span');

        // Loop through the span elements
        for (let i = 0; i < spans.length; i++) {
            let span = spans[i];

            // Example based on text content:
            if (span.textContent.includes($_('NewShopTabDailyDeals'))) {
                // Simulate a click on the span
                span.click();
                break;
            }
        }
    }

    let gift_friend_modal_open = false;
    const gift_friend_modal_show = () => gift_friend_modal_open = true;
    export let gift_friend_modal_hide = () => {
        redirectToDailyDeals();
        gift_friend_modal_open = false;
        logMetric({button: 'gift-friend-close-modal'});
    };

    castleLevel.subscribe(data => {
        if (data < 15) {
            gift_friend_modal_show();
        }
    });

    if (localStorage.getItem("giftFriendSeen") === null) {
        localStorage.setItem("giftFriendSeen", "true");
    }

    $: giftPackButtonClass = {
        '1': 'green',
        '2': 'blue',
        '3': 'purple',
        '4': 'orange'
    }[$giftFriend.selectedPack.rarity] || 'green';
</script>

<picture class="gift-friend-background">
    <source srcset={giftBackgroundUrl[0]} type="image/webp">
    <source srcset={giftBackgroundUrl[1]} type="image/jpg">
    <img alt="Gift Friend Background" src={giftBackgroundUrl[0]}>
</picture>

<div class="giftFriend">
    <Breadcrumbs crumbs={crumbs} on:hashchange="{handleHashChange}"/>
    {#if hashValue === 'pack-selection'}
        <div class="pack-selection-wrapper">
            <SendingTo player={player} on:resetPlayer="{resetGiftFriend}"/>
            <h2>{$_("GiftFriendPackSelectionTitle")}</h2>
            <section class="pack-listing">
                <PackListing
                        storeSection="giftFriend"
                        title={$_('GiftFriend')}
                />
            </section>
        </div>
    {:else if hashValue === 'gift-note'}
        <div class="gift-note-wrapper">
            <SendingTo player={player} on:resetPlayer="{resetGiftFriend}"/>
            <h2>{$_("GiftFriendMessageTitle")}</h2>
            <Button class="change-pack {giftPackButtonClass}" primary on:click={resetPack}>
                <div class="left-side">
                    {#if $giftFriend.selectedPack.holidayTheme === 'winter'}
                        <picture class="gift-pack-icon">
                            <source srcset={giftPackWinterIconUrl[0]} type="image/webp">
                            <source srcset={giftPackWinterIconUrl[1]} type="image/jpg">
                            <img alt="Winter Gift Pack Icon" src={giftPackWinterIconUrl[0]} width="150px">
                        </picture>
                    {:else}
                        <picture class="gift-pack-icon">
                            <source srcset={giftPackIconUrl[0]} type="image/webp">
                            <source srcset={giftPackIconUrl[1]} type="image/jpg">
                            <img alt="Gift Pack Icon" src={giftPackIconUrl[0]} width="150px">
                        </picture>
                    {/if}
                    <picture class="greenCheckmark">
                        <source srcset={greenCheckmarkImage[0]} type="image/webp">
                        <source srcset={greenCheckmarkImage[1]} type="image/jpg">
                        <img alt="Green Checkmark" src={greenCheckmarkImage[0]}>
                    </picture>
                    <span class="price">{$giftFriend.selectedPackDetails.subTitle}</span>
                </div>
                <div class="right-side">
                    <span class="price">{$number($giftFriend.selectedPack.paymentHub.price.amount / 100, {
                        style: 'currency',
                        currency: $giftFriend.selectedPack.paymentHub.price.currency,
                    })}</span>
                    <span>{$_('Change')}</span>
                </div>
            </Button>
            <div class="gift-message-wrapper">
                <textarea
                        class="gift-message"
                        bind:value={giftMessage}
                        placeholder="{$_('GiftFriendPlaceholderMessage')}"
                        maxlength="300"
                ></textarea>
                <span class="maximum">{giftMessage.length + '/300'}</span>
            </div>
            <Button
                    primary
                    class="continue-payment orange"
                    on:click={continuePayment}
            >
                <img class="snow3" src="/images/snow2.png" alt=""/>
                {$_('ContinuePayment')}
            </Button>
            <hr>
            <div class="recipient-info">
                {@html $_("GiftFriendMessageInfo").replace(/\. /g, ".<br/>")}
            </div>
        </div>
    {:else}
        <div class="recipient-wrapper">
            <picture class="gift-icon">
                <source srcset={giftIconUrl[0]} type="image/webp">
                <source srcset={giftIconUrl[1]} type="image/jpg">
                <img alt="Gift Icon" src={giftIconUrl[0]}>
            </picture>
            <h2>{$_("GiftFriendRecipientTitle")}</h2>
            <GiftPlayerSearchBox players={players} autocomplete={false} on:playerSelection="{handlePlayerSelection}"/>
        </div>
    {/if}
</div>

<Modal class="gift-friend-modal-wrapper" bind:open={gift_friend_modal_open}>
    <GiftFriendModal {gift_friend_modal_hide}/>
</Modal>

<style lang="scss">
  :global {
    .gift-friend-background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .hide-background span {
      background: none !important;
    }

    .giftFriend {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;
      margin-top: -50px;

      @media screen and (max-width: 700px) {
        margin-top: -35px;
      }

      h2 {
        font-family: var(--font-family-sans-condensed);
        font-size: clamp(1.6rem, 4vw, 30px);
        color: #ffedbf;
        margin-bottom: 25px;
      }

      .recipient-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;
        margin-top: 3%;
        width: 100%;

        .gift-icon {
          max-width: 20%;
          margin-bottom: -1%;
        }
      }

      .pack-selection-wrapper, .gift-note-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 1%;
        width: 100%;
      }

      .pack-selection-wrapper .pack-listing {
        margin-top: 60px;
        width: 100%;

        .pack.giftFriend {
          margin: auto;
        }
      }

      .gift-note-wrapper {
        .change-pack {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 800px;
          height: 80px;
          position: relative;
          margin: 0;
          max-width: 95%;

          @media screen and (max-width: 374px) {
            height: 50px;
          }

          @media screen and (max-width: 424px) {
            height: 60px;
          }

          .left-side, .right-side {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1%;
            font-size: clamp(1.2rem, 4vw, 20px);

            .price {
              color: rgb(254, 255, 217);
              font-weight: bold;
              text-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15);
            }
          }

          .left-side {
            position: relative;
            right: 20px;

            .gift-checkmark-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              max-width: 116px;
            }

            .greenCheckmark {
              position: absolute;
              left: 2px;
              top: 32px;

              @media screen and (max-width: 424px) {
                width: 60px;
                left: 0;
                top: 20px;
              }

              @media screen and (max-width: 374px) {
                width: 50px;
                left: 0;
                top: 15px;
              }

              @media screen and (max-width: 320px) {
                width: 45px;
                left: 2px;
                top: 18px;
              }

              @media screen and (max-width: 300px) {
                width: 30px;
                left: 3px;
                top: 14px;
              }

              @media screen and (max-width: 285px) {
                width: 25px;
                left: 0;
                top: 13px;
              }

              @media screen and (max-width: 275px) {
                width: 20px;
                left: 0;
                top: 13px;
              }

              @media screen and (max-width: 270px) {
                display: none;
              }

            }
          }

          .right-side {
            gap: 15%;
          }
        }

        .gift-message-wrapper {
          width: 800px;
          height: 270px;
          margin-top: 5px;
          position: relative;
          max-width: 95%;

          .gift-message {
            border-radius: 10px;
            background-color: #fff0be;
            box-shadow: inset 0 0 76px 0 rgba(255, 226, 91, 0.47);
            color: #000;
            padding: 20px;
            font-size: clamp(1.2rem, 4vw, 20px);
            width: 100%;
            height: 100%;
            resize: none;
            caret-color: #3ca1ff;

            &::placeholder {
              font-family: var(--font-family-noto-sans);
              color: rgba(57, 25, 14, 0.502);
            }
          }

          .maximum {
            font-family: var(--font-family-noto-sans);
            font-size: clamp(1.2rem, 4vw, 18px);
            font-weight: bold;
            color: rgb(36, 13, 7);
            position: absolute;
            bottom: 15px;
            right: 30px;
          }
        }

        .continue-payment {
          width: 250px;
          height: 60px;
          font-size: clamp(1.6rem, 5vw, 20px);
          margin-top: 30px;
          max-width: 90%;
        }

        hr {
          background-color: rgb(255, 255, 255, 0.15);
          width: 828px;
          height: 1px;
          margin: 30px 0;
          max-width: 95%;
        }

        .recipient-info {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: clamp(1.2rem, 5vw, 14px);
          color: rgb(232, 222, 196);
          text-shadow: 1px 1.732px 0 rgba(0, 0, 0, 0.4);
          margin-bottom: 300px;
          text-align: center;
          max-width: 95%;
        }
      }
    }
  }
</style>
