<div class="effectWrapper">
    {#each Array(50) as i}
        <div class="snow"></div>
    {/each}
</div>

<style lang="scss">
  .effectWrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    filter: drop-shadow(0 0 10px white);
    position: fixed;
    z-index: 10;
    pointer-events: none;
  }

  @function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }

  .snow {
    $total: 200;
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;

    @for $i from 1 through $total {
      $random-x: calc(random(1000000) * 0.0001vw);
      $random-offset: calc(random_range(-100000, 100000) * 0.0001vw);
      $random-x-end: calc($random-x + $random-offset);
      $random-x-end-yoyo: calc($random-x + ($random-offset / 2));
      $random-yoyo-time: calc(random_range(30000, 80000) / 100000);
      $random-yoyo-y: calc($random-yoyo-time * 100vh);
      $random-scale: calc(random(10000) * 0.0001);
      $fall-duration: calc(random_range(10, 30) * 1s);
      $fall-delay: calc(random(30) * -1s);

      &:nth-child(#{$i}) {
        opacity: random(10000) * 0.0001;
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }

      @keyframes fall-#{$i} {
          #{percentage($random-yoyo-time)} {
            transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
          }

        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
  }
</style>