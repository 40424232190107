<script>
    import greenCheckmark from '../../assets/gold_rush/green_checkmark.png?format=webp;jpg';
    import {range} from "../../utils.js";
    import {adventCalendarEvent, currentAdventClaimed} from "../../stores.js";
    import ItemsListing from "../ItemsListing.svelte";
    import {_} from "svelte-i18n";
    import {claim_gold_rush_route, game_name} from "../../constants.js";
    import Loader from "../Loader.svelte";
    import {resourceDetails} from "../../globals.js";

    export let globals = {};

    let greenCheckmarkImage = greenCheckmark;
    let processing = false;
    let errorMessage;

    // ===  Want each of 24 calendar doors to open only if number matches today's date or earlier:

    // Given date string
    const dateString = $adventCalendarEvent.startAt;

    // Parse the date string into a Date object
    const eventStartDate = new Date(dateString);

    // Check if eventStartDate is a valid date
    if (isNaN(eventStartDate.getTime())) {
        throw new Error("Invalid event start date");
    }

    // Get the current date
    const currentDate = new Date();

    // Check if currentDate is a valid date
    if (isNaN(currentDate.getTime())) {
        throw new Error("Invalid current date");
    }

    // Calculate the difference in time (milliseconds)
    const timeDifference = currentDate.getTime() - eventStartDate.getTime();

    // Convert the time difference from milliseconds to days
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

    // Store the results in variables
    const todaysDate = eventStartDate.getUTCDate();
    const todaysDateDifference = dayDifference;
    let currentPrize = todaysDateDifference;
    let currentPrizeItems;

    let claimed;
    currentAdventClaimed.subscribe(value => {
        claimed = value;
    });

    // Function to toggle the claimed status
    function toggleClaimed() {
        currentAdventClaimed.update(value => !value);
    }

    setTimeout(function () {

        // ===  Get the modal popup box + content + close span

        const modal = document.getElementById('modalPopup');

        const modalDailyDate = document.querySelector('.dailyDate');
        const spanClose = document.querySelector('.close');

        // ===  Array of popup messages for each door

        const moreInfo = [
            {
                date: "1st DECEMBER",
            },

            {
                date: "2nd DECEMBER",
            },

            {
                date: "3rd DECEMBER",
            },

            {
                date: "4th DECEMBER",
            },

            {
                date: "5th DECEMBER",
            },

            {
                date: "6th DECEMBER",
            },

            {
                date: "7th DECEMBER",
            },

            {
                date: "8th DECEMBER",
            },

            {
                date: "9th DECEMBER",
            },

            {
                date: "10th DECEMBER",
            },

            {
                date: "11th DECEMBER",
            },

            {
                date: "12th DECEMBER",
            },

            {
                date: "13th DECEMBER",
            },

            {
                date: "14th DECEMBER",
            },

            {
                date: "15th DECEMBER",
            },

            {
                date: "16th DECEMBER",
            },

            {
                date: "17th DECEMBER",
            },

            {
                date: "18th DECEMBER",
            },

            {
                date: "19th DECEMBER",
            },

            {
                date: "20th DECEMBER",
            },

            {
                date: "21st DECEMBER",
            },

            {
                date: "22nd DECEMBER",
            },

            {
                date: "23rd DECEMBER",
            },

            {
                date: "24th DECEMBER",
            },
        ];

        // === no hover action if higher than today's date (add class 'no-hover')

        function noHover() {

            const doorNumber = this.innerHTML;
            const calendarNum = parseInt(doorNumber, 10);

            console.log('calendarNum:', calendarNum);
            console.log('todaysDate:', todaysDate);

            if (calendarNum > todaysDate) {
                this.parentNode.classList.add("no-hover");
            }

        }

        // === door-front: hover + click events

        const doorFronts = document.querySelectorAll(".front");

        for (let i = 0; i < doorFronts.length; i++) {
            // doorFronts[i].addEventListener("mouseover", noHover);
            doorFronts[i].addEventListener("click", clickFront);
        }

        // === door-back: click event

        const doorBacks = document.querySelectorAll(".back");

        for (let i = 0; i < doorBacks.length; i++) {
            doorBacks[i].addEventListener("click", clickBack);
        }

        // === door-front: click toggles class 'open'

        function clickFront() {

            const numberClicked = this.innerHTML;
            const calendarNum = parseInt(numberClicked, 10);
            // console.log(numberClicked);
            // console.log(calendarNum);

            if (calendarNum === todaysDateDifference) {
                this.parentNode.classList.toggle("open");
                clickBack(this.innerHTML);
                currentPrize = calendarNum;
                collectAdvent();
            }

        }

        // === door-back: click opens the modal + displays corresponding content

        function clickBack(calendarNumber) {
            let calendarNum;

            // find number of corresponding door-front (as back has no innerHTML of its own) //
            if (calendarNumber > 0) {
                calendarNum = calendarNumber;
            } else {
                calendarNum = this.previousElementSibling.innerHTML;
            }
            // -1 from door-front to get correct message array index //
            let calendarNumIndex = calendarNum - 1;

            // console.log('calendarNumber:', calendarNumber);
            // console.log('calendarNum:', calendarNum);
            // console.log('calendarNumIndex:', calendarNumIndex);

            // change modal css to display it //
            modal.style.display = "block";

            // put message from array into modal content //

            modalDailyDate.innerHTML = `<span>${moreInfo[calendarNumIndex].date}</span>`;

            // can toggle door closed again IF want to: //
            // this.parentNode.classList.toggle("open");

            currentPrizeItems = $adventCalendarEvent.prizes[`prize${calendarNum}`]?.items || [];
        }


        // === click span (x) to close modal

        spanClose.onclick = function () {
            modal.style.display = "none";
        }

        // === click anywhere outside modal to close it

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        }

        Object.keys($adventCalendarEvent.claimablePrizes).forEach(prizeKey => {
            const match = prizeKey.match(/\d+/); // Extract numbers from the key
            if (match) {
                const prizeNumber = match[0]; // Get the first match (the number)

                // Construct the selector for the .dayX .front element
                const selector = `.pack-listing .day${prizeNumber} .front`;

                // Find the element using the constructed selector
                const frontElement = document.querySelector(selector);

                if (frontElement) {
                    // Toggle the "open" class on the parent node of the .front element
                    frontElement.parentNode.classList.toggle("open");
                }
            }
        });
    }, 250);

    globals.resourceDetails = resourceDetails;
    globals.formatTimeFriendly = formatTimeFriendly;

    function formatTimeFriendly(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let timeString = `${minutes} ${$_('Minute')}`;

        if (seconds - 1 > 60 * 60 * 24) {
            timeString = `${days} ${$_('Days')}`;
        } else if (seconds > 60 * 60) {
            timeString = `${hours} ${$_('Hours')}`;
        } else if (seconds >= 60) {
            timeString = `${minutes} ${$_('Minutes')}`;
        }

        return timeString;
    }

    async function collectAdvent() {
        try {
            processing = true;
            const response = await fetch(claim_gold_rush_route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prizeId: currentPrize,
                    eventId: $adventCalendarEvent.id,
                }),
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                errorMessage = message;
                throw new Error(message);
            }

            const res = await response.text();
            // console.log(res)
            toggleClaimed();

        } catch (error) {
            Rollbar.error("Error collecting gold rush " + error)
        } finally {
            processing = false;
        }
    }

    if (localStorage.getItem("adventCalendarSeen") === null) {
        localStorage.setItem("adventCalendarSeen", "true");
    }

    $: prizes = Object.values($adventCalendarEvent.prizes);
    $: currentPrizeItems = $adventCalendarEvent.prizes[`prize${currentPrize}`]?.items || [];
</script>

<div class="adventCalendar">
    <!-- CALENDAR DOORS -->

    <section class="calendar-grid">

        <div class="title">
            <div class="title-lines-container">
                <img alt={game_name} class="logo" src="images/logo.png" title={game_name}/>
                <img alt="{game_name} advent calendar" class="adventTitle" src="images/adventCalendar/title.png"
                     title="{game_name} advent calendar"/>
            </div><!-- closes title-lines-container -->
            <p class="title-intro">A festive event each day in War and Peace. Click a door to claim your rewards. <b>Check
                back daily to see what's on!</b></p>
        </div><!-- closes title -->

        {#each range(28, 1) as day}
            {#if day > 24}
                <div class="day{day}">

                </div>
            {:else}
                <div class="day{day} day">
                    <div class="door {day !== todaysDateDifference ? 'no-hover' : ''}
                                     {day < todaysDateDifference ? 'faded' : ''}
                                     {day === 24 ? 'special' : ''}
                                     {day === todaysDateDifference ? 'current' : ''}
                                     {$currentAdventClaimed && day === todaysDateDifference ? 'open' : ''}">
                        <div class="front">{day}</div>
                        <div class="back">
                            <ItemsListing storePack={{}} goldAmount={0} globals={globals} items={prizes[day - 1].items}
                                          hasMask={false}/>
                            <picture class="greenCheckmark">
                                <source srcset={greenCheckmarkImage[0]} type="image/webp">
                                <source srcset={greenCheckmarkImage[1]} type="image/jpg">
                                <img alt="Green Checkmark" src={greenCheckmarkImage[0]}>
                            </picture>
                        </div>
                    </div>
                </div>
            {/if}
        {/each}

    </section><!-- closes calendar-grid -->

    <!-- MODAL POPUP BOX -->

    <div id="modalPopup" class="modal">

        <!-- MODAL CONTENT -->

        <div class="modalContent">

            <span class="close">&times;</span>
            <h3 class="dailyDate"></h3>
            {#if processing}
                <Loader/>
            {:else}
                {#if errorMessage}
                    <p>{errorMessage}</p>
                {:else}
                    <h4 class="dailyTitle">You have claimed:</h4>
                    <ItemsListing storePack={{}} goldAmount={0} globals={globals} items={currentPrizeItems}
                                  hasMask={false}/>
                {/if}
            {/if}
        </div><!-- closes modalContent -->

    </div><!-- closes modalPopup -->
</div>

<style lang="scss">
  @import 'src/styles/vars.scss';

  :global {
    .adventCalendar {
      display: flex;
      justify-content: center;
      align-items: center;

      /* === SCSS COLOUR VARIABLES === */

      $backgroundteal: #266c8e;
      $textteal: #0a5656;
      $borderpink: #96e3ff;
      $textpink: #96e3ff;
      $darkslate: #344f63;
      $doorback: #135270;
      $titlebackground: #ffffff;
      $numbers: #ffffff;
      $linkbuttonhover: #2883af;

      /* SCSS RESPONSIVE TITLE TYPE @MIXIN */

      /// Viewport sized typography with minimum and maximum values
      /// @author Eduardo Boucas (@eduardoboucas)
      ///
      /// @param {Number}   $responsive  - Viewport-based size
      /// @param {Number}   $min         - Minimum font size (px)
      /// @param {Number}   $max         - Maximum font size (px)
      ///                                  (optional)
      /// @param {Number}   $fallback    - Fallback for viewport-
      ///                                  based units (optional)
      ///
      /// @example scss - 5vw font size (with 50px fallback),
      ///                 minumum of 35px and maximum of 150px
      /// @include responsive-font(5vw, 35px, 150px, 50px);

      @mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
        $responsive-unitless: $responsive / ($responsive - $responsive + 1);
        $dimension: if(unit($responsive) == 'vh', 'height', 'width');
        $min-breakpoint: $min / $responsive-unitless * 100;

        @media (max-#{$dimension}: #{$min-breakpoint}) {
          font-size: $min;
        }

        @if $max {
          $max-breakpoint: $max / $responsive-unitless * 100;

          @media (min-#{$dimension}: #{$max-breakpoint}) {
            font-size: $max;
          }
        }

        @if $fallback {
          font-size: $fallback;
        }

        font-size: $responsive;
      }

      /* =============================== */

      body {
        background-color: $backgroundteal;
        background-position: center center;
        background-repeat: no-repeat, repeat;
        background-size: 120vw;
        font-family: 'Montserrat', Verdana;
      }

      /* === MOBILE-FIRST GRID LAYOUT === */

      .calendar-grid {
        display: grid;
        width: 96%;
        max-width: 900px;
        margin: 2% auto;
        margin-top: 0;

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-gap: 10px 0;

        grid-template-areas:    "title    title"
                                "day1     day2"
                                "day3     day4"
                                "day5     day6"
                                "day7     day8"
                                "day9     day10"
                                "day11    day12"
                                "day13    day14"
                                "day15    day16"
                                "day17    day18"
                                "day19    day20"
                                "day21    day22"
                                "day23    day24";
      }

      @media only screen and (min-width: 380px) {
        .calendar-grid {
          grid-template-columns: repeat(3, 1fr);
          grid-template-areas:    "title      title     title"
                                  "day1       day2      day3"
                                  "day4       day5      day6"
                                  "day7       day8      day9"
                                  "day10      day11     day12"
                                  "day13      day14     day15"
                                  "day16      day17     day18"
                                  "day19      day20     day21"
                                  "day22      day23     day24";
        }
      }

      @media only screen and (min-width: 505px) {
        .calendar-grid {
          grid-template-columns: repeat(4, 1fr);
          grid-template-areas:    "title      title     title     title"
                                  "day1       day2      day3      day4"
                                  "day5       day6      day7      day8"
                                  "day9       day10     day11     day12"
                                  "day13      day14     day15     day16"
                                  "day17      day18     day19     day20"
                                  "day21      day22     day23     day24";
        }
      }

      @media only screen and (min-width: 625px) {
        .calendar-grid {
          grid-template-columns: repeat(5, 1fr);
          grid-template-areas:    "title      title     title     title      title"
                                  "title      title     title     title      title"
                                  "title      title     title     title      title"
                                  "day1       day2      day3      day4       day5"
                                  "day6       day7      day8      day9       day10"
                                  "day11      day12     day13     day14      day15"
                                  "day16      day17     day18     day19      day20"
                                  "day21      day22     day23     day24      day25";
        }
      }

      /* MEDIA QUERY -- DESKTOP LAYOUT */

      @media only screen and (min-width: 870px) {
        .calendar-grid {
          grid-template-columns: repeat(7, 1fr);
          grid-template-areas:    "title      title     title     title      title      title      title"
                                  "title      title     title     title      title      title      title"
                                  "title      title     title     title      title      title      title"
                                  "day1       day2      day3      day4       day5       day6       day7"
                                  "day8       day9      day10     day11      day12      day13      day14"
                                  "day15      day16     day17     day18      day19      day20      day21"
                                  "day22      day23     day24     day25      day26      day27      day28";
        }
      }

      /* === INDIVIDUAL GRID ITEMS ===  */

      .title {
        grid-area: title;
        text-align: center;
        color: $textteal;
      }

      .title-lines-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 130px;

        .logo {
          width: 225px;
        }

        .adventTitle {
          width: 475px;
          margin-left: -170px;
        }
      }

      .bigger-text {
        @include responsive-font(3.5vw, 26px, 50px, 40px);
      }

      .title-intro {
        font-family: var(--font-family-sans-condensed);
        color: rgb(216, 183, 130);
        margin-top: 10px;
      }

      .day {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .day1 {
        grid-area: day1;
      }

      .day2 {
        grid-area: day2;
      }

      .day3 {
        grid-area: day3;
      }

      .day4 {
        grid-area: day4;
      }

      .day5 {
        grid-area: day5;
      }

      .day6 {
        grid-area: day6;
      }

      .day7 {
        grid-area: day7;
      }

      .day8 {
        grid-area: day8;
      }

      .day9 {
        grid-area: day9;
      }

      .day10 {
        grid-area: day10;
      }

      .day11 {
        grid-area: day11;
      }

      .day12 {
        grid-area: day12;
      }

      .day13 {
        grid-area: day13;
      }

      .day14 {
        grid-area: day14;
      }

      .day15 {
        grid-area: day15;
      }

      .day16 {
        grid-area: day16;
      }

      .day17 {
        grid-area: day17;
      }

      .day18 {
        grid-area: day18;
      }

      .day19 {
        grid-area: day19;
      }

      .day20 {
        grid-area: day20;
      }

      .day21 {
        grid-area: day21;
      }

      .day22 {
        grid-area: day22;
      }

      .day23 {
        grid-area: day23;
      }

      .day24 {
        grid-area: day24;
      }

      .day25 {
        grid-area: day25;
      }

      .day26 {
        grid-area: day26;
      }

      .day27 {
        grid-area: day27;
      }

      .day28 {
        grid-area: day28;
      }

      /* ===  DOOR STYLES ===  */

      .calendar-grid input {
        display: none;
      }

      .door {
        //width: 100%;
        //height: 100%;
        perspective: 1000px;
        transform-style: preserve-3d;
        transition: all 300ms;
        border-radius: 10px;
        cursor: pointer;
        width: 110px;
        height: 108px;
        font-family: var(--font-family-sans-condensed);
        font-size: 48px;
        font-weight: bold;
        color: $numbers;
        background-image: url('/images/adventCalendar/default.png');
        background-size: cover;

        &.no-hover {
          cursor: default;
          pointer-events: none;

          &.open {
            cursor: pointer;
            pointer-events: auto;

            &:hover {
              .back {
                filter: brightness(1.1);
              }
            }
          }
        }

        &:not(.no-hover):hover {
          .front, .back {
            filter: brightness(1.1);
          }
        }

        &.faded {
          opacity: 0.5;

          .back {
            display: none;
          }

          &.open {
            opacity: 1;

            .back {
              display: block;
            }
          }
        }

        &.current {
          background-image: url('/images/adventCalendar/current.png');
          color: rgb(102, 216, 9);
        }

        &.special {
          background-image: url('/images/adventCalendar/special.png');
          color: rgb(255, 180, 0);
        }
      }

      .door div {
        position: absolute;
        height: 100%;
        width: 100%;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .door .back {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('/images/adventCalendar/claimed.png');
        transform: rotateY(180deg);

        picture {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            position: absolute;
            top: 45px;
            left: 50px;
          }
        }

        .items {
          background: none;
          margin: 0;
          border-radius: 6px;
          z-index: -1;

          .item {
            justify-content: center;
            height: 90px;
            pointer-events: none;

            .item-icon {
              width: 90px;
              height: 90px;
              max-width: 90px;
              max-height: 90px;

              &:after {
                max-width: 90px;
              }
            }

            .item-title, .item-amount {
              display: none;
            }
          }
        }

        .items > div > *:not(:first-child) {
          display: none;
        }
      }

      .open {
        transform: rotateY(180deg);
      }

      /* ===  MODAL (BACKGROUND) ===  */

      .modal {
        display: none; /* Hidden by default */
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
      }

      /* === MODAL CONTENT + BOX ===  */

      .modalContent {
        background-color: rgb(240, 214, 166);
        margin: 15% auto;
        padding: 20px 20px 30px;
        border-radius: 6px;
        width: 76%;
        max-width: 600px;
        min-height: 200px;
        letter-spacing: 0.5px;
        line-height: 1.4em;

        p {
          font-size: 0.9em;
        }

        .items {
          //background: none;
          margin: 0;

          .item {
            //display: flex;
            //justify-content: center;
            //align-items: center;
            //flex-wrap: wrap;

            .item-icon {
            }

            .item-title {
              //flex-grow: 0;
            }

            .item-amount {
            }

            .item-title, .item-amount {
              font-size: 22px;
              font-family: var(--font-family-sans-condensed);
              color: rgb(76, 32, 12);
              font-weight: bold;
            }
          }
        }

        .loader {
          top: 50px !important;
          position: relative !important;
        }
      }

      .dailyTitle {
        font-family: var(--font-family-sans-condensed);
        font-size: 20px;
        color: rgba(55, 29, 17, 0.502);
      }

      .dailyDate {
        font-family: var(--font-family-sans-condensed);
        font-size: 28px;
        height: 40px;
        background-image: url('/images/adventCalendar/modal_title_background.png');
        background-size: cover;
        color: rgb(255, 249, 227);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        position: relative;
        top: -16px;
        left: -16px;

        span {
          position: relative;
          left: 15px;
        }
      }

      .highlight {
        font-weight: bold;
        color: rgb(76, 32, 12);
      }

      /* ===  MODAL CLOSE BUTTON (X) ===  */

      .close {
        color: black;
        float: right;
        font-size: 48px;
        line-height: 26px;
        font-weight: bold;
        position: relative;
        top: -10px;

        &:hover,
        &:focus {
          opacity: 0.8;
          text-decoration: none;
          cursor: pointer;
        }
      }

      @media only screen and (max-width: 519px) {
        .title-line1 {
          @include responsive-font(10vw, 34px, 52px, 38px);
        }
        .title-line2 {
          @include responsive-font(8vw, 27px, 48px, 35px);
        }
        .bigger-text {
          @include responsive-font(10vw, 32px, 52px, 40px);
        }
        .title-lines-container {
          .logo {
            width: 175px;
          }

          .adventTitle {
            width: 325px;
            margin-left: -120px;
          }
        }
      }

      @media only screen and (max-width: 375px) {
        .title-lines-container {
          height: 80px;

          .logo {
            width: 135px;
          }

          .adventTitle {
            width: 285px;
            margin-left: -100px;
          }
        }

        .dailyDate {
          font-size: 18px;
        }

        .dailyTitle {
          font-size: 16px;
        }

        .item-title, .item-amount {
          font-size: 14px !important;
        }
      }

      @media only screen and (max-width: 690px) {
        .modalContent {
          position: relative;
          top: 70px;
        }

        .door {
          font-size: 46px;
        }
      }

      @media only screen and (max-width: 300px) {
        .title-lines-container {
          height: 50px;

          .logo {
            width: 85px;
          }

          .adventTitle {
            width: 195px;
            margin-left: -50px;
          }
        }

        .dailyDate {
          background: none;
          color: rgb(76, 32, 12);
        }

        .modalContent {
          position: relative;
          top: 90px;
        }
      }

    }
  }
</style>
