<script>
    import {writable} from 'svelte/store';
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faCircleXmark, faSearch} from '@fortawesome/free-solid-svg-icons';
    import {_, number} from "svelte-i18n";
    import {Button} from "svelte-chota";
    import {createEventDispatcher} from "svelte";
    import tippy from 'svelte-tippy';
    import 'tippy.js/dist/tippy.css'; // optional
    import 'tippy.js/animations/shift-away-subtle.css';
    import {search_user_route} from "../constants.js";
    import {castleLevel, user} from "../stores.js";
    import {logMetric} from "../metrics.js";
    import infoIcon from '../assets/gift_friend/info_icon.png?format=webp;jpg';

    export let players = [];
    export let autocomplete = true;

    let search = '';
    let results = [];
    let playerSelection = {};
    let playerNumber = 0;
    let playerSearched = false;
    let playerSearchBox;
    let lastSearchedValue = null;
    let infoIconUrl = infoIcon;
    let errorMessage = '';

    const dispatch = createEventDispatcher();
    const searchStore = writable('');

    if (autocomplete) {
        searchStore.subscribe(value => {
            search = value;
            results = players.filter(player => player.name.includes(search) || player.id.toString().includes(search));
        });
    }

    function updatePlayerSelection(player) {
        playerSelection = player;
        dispatch('playerSelection', playerSelection);
        return false;
    }

    function handleFocus() {
        playerSearchBox.style.backgroundColor = '#fffcdf';
    }

    function handleBlur() {
        playerSearchBox.style.backgroundColor = '#ffeeb3';
    }

    function handleClear() {
        playerSearched = false;
        lastSearchedValue = null;
        searchStore.set('');
    }

    function handleInput(event) {
        if (event.key === 'Enter') {
            searchPlayer();
        } else {
            playerSearched = false;
        }
    }

    function convertIfNumeric(str) {
        if (!isNaN(str)) {
            return Number(str);
        } else {
            return str;
        }
    }

    async function searchPlayer() {
        if ($castleLevel < 15) {
            return;
        }

        let formattedInput = $searchStore;
        formattedInput = convertIfNumeric(formattedInput);

        if ($searchStore.trim().length === 0 ||
            $searchStore === lastSearchedValue
        ) {
            return;
        }

        if (
            $searchStore.toLowerCase() === $user.user.username.toLowerCase() ||
            parseInt($searchStore, 10) === $user.user.id
        ) {
            errorMessage = $_("GiftFriendCannotGiftSelf")
            playerSearched = true;
            playerNumber = 0;
            results = [];
            return;
        }

        lastSearchedValue = $searchStore;

        try {
            const response = await fetch(search_user_route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    field: formattedInput,
                }),
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const res = await response.json();
            console.log(res)

            if (res.statusCode === 500) {
                errorMessage = res.message;
                playerNumber = 0;
                results = [];
                playerSearched = true;
                return;
            }

            if (res.message !== 'User not found' && !res.errorMessage) {
                playerNumber = 1;
                results = [];
                results.push(res);
                errorMessage = '';
            } else {
                if (res.errorMessage) {
                    errorMessage = res.errorMessage;
                } else {
                    errorMessage = '';
                }
                playerNumber = 0;
                results = [];
            }

            playerSearched = true;
        } catch (error) {
            Rollbar.error("Error searching player in gift friend " + error)
        } finally {
            handleBlur();
            logMetric({button: 'gift-friend-search-player'});
        }
    }
</script>

<div class="player-search-box {autocomplete ? 'autocomplete' : 'default'}" bind:this={playerSearchBox}>
    {#if autocomplete}
        <input bind:value={$searchStore} on:focus={handleFocus} on:blur={handleBlur} type="search"
               placeholder="Search for player name or clan tag..."/>
        <Fa icon={faSearch} primaryColor="#39190e" size="1.5x"/>
        <div class="results {search.length >= 2 && results.length > 0 ? 'show' : ''}">
            {#each results as player (player.name)}
                <div class="result {player.castleLevel < 5 ? 'faded' : ''}">
                    <div class="player-details-wrapper">
                        <img src="/images/flags/{player.countryCode}.png" alt={player.countryCode + ' flag'}
                             width="32px"/>
                        <div class="player-details">
                            <div class="player-details-top">
                                {#if player.allianceAcronym}
                                    <div class="player-clan">{'<' + player.allianceAcronym + '>'}</div>
                                {/if}
                                <div class="player-name">
                                    {#each player.name.split(new RegExp(`(${search})`, 'i')) as part, i (i)}
                                        {#if part.toLowerCase() === search.toLowerCase()}
                                            <span class="highlight">{part}</span>
                                        {:else}
                                            {part}
                                        {/if}
                                    {/each}
                                </div>
                            </div>
                            <div class="player-details-bottom">
                                <div class="player-power">{$_('Power')}: {$number(player.power)}</div>
                            </div>
                        </div>
                    </div>
                    {#if player.castleLevel >= 5}
                        <div class="buy-gift-wrapper">
                            <Button class="buy-gift green" primary on:click={updatePlayerSelection(player)}>
                                <img class="snow3" src="/images/snow2.png" alt=""/>
                                {$_('BuyGift')}
                            </Button>
                        </div>
                    {:else}
                        <div class="buy-gift-wrapper"
                             use:tippy={{
                        content: $_('CastleLevel5Restriction'),
                        theme: 'shop',
                        boundary: 'scrollingContainer',
                        arrow: true,
                        flip: false,
                        placement: 'top-start',
                        animation: 'shift-away-subtle',
                      }}>
                            <Button class="buy-gift green" primary disabled>
                                {$_('NotEligible')}
                            </Button>
                        </div>
                    {/if}
                </div>
            {/each}
        </div>
    {:else}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a class="searchIcon-wrapper" href="javascript:" on:click={searchPlayer}>
            <Fa icon={faSearch} class="searchIcon" primaryColor="#39190e" size="1.5x"/>
        </a>
        <input bind:value={$searchStore} on:focus={handleFocus} on:blur={handleBlur} on:keydown={handleInput}
               type="search"
               placeholder="{$_('GiftFriendSearchPlaceholder')}"/>
        {#if $searchStore.length > 0}
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a class="circleXmark-wrapper" href="javascript:" on:click={handleClear}>
                <Fa icon={faCircleXmark} class="circleXmark" primaryColor="#39190e" size="1.5x"/>
            </a>
        {/if}
    {/if}
</div>

{#if !autocomplete && playerSearched}
    <div class="results-for">
        {#if errorMessage.length > 0}
            <span>{errorMessage}</span>
        {:else}
            <span>{playerNumber} {playerNumber === 1 ? $_("ResultFor") : $_("ResultsFor")} <i>{$searchStore}</i>
                {#if playerNumber === 0} - {$_("GiftFriend0ResultsFoundText")}{/if}</span>
        {/if}
    </div>
    <hr class="results-for-hr">
    <div class="results no-auto-complete">
        {#each results as player (player.name)}
            <div class="result {player.castleLevel < 5 ? 'faded' : ''}">
                <div class="player-details-wrapper">
                    <img src="/images/flags/{player.countryCode}.png" alt={player.countryCode + ' flag'}
                         width="32px"/>
                    <div class="player-details">
                        <div class="player-details-top">
                            {#if player.allianceAcronym}
                                <div class="player-clan">{'<' + player.allianceAcronym + '>'}</div>
                            {/if}
                            <div class="player-name">
                                {#each player.name.split(new RegExp(`(${search})`, 'i')) as part, i (i)}
                                    {#if part.toLowerCase() === search.toLowerCase()}
                                        <span class="highlight">{part}</span>
                                    {:else}
                                        {part}
                                    {/if}
                                {/each}
                            </div>
                        </div>
                        <div class="player-details-bottom">
                            <div class="player-power">{$_('Power')}: {$number(player.power)}</div>
                        </div>
                    </div>
                </div>
                {#if player.castleLevel >= 5}
                    <div class="buy-gift-wrapper">
                        <Button class="buy-gift green" primary on:click={updatePlayerSelection(player)}>
                            <img class="snow3" src="/images/snow2.png" alt=""/>
                            {$_('BuyGift')}
                        </Button>
                    </div>
                {:else}
                    <div class="buy-gift-wrapper"
                         use:tippy={{
                        content: $_('CastleLevel5Restriction'),
                        theme: 'shop',
                        boundary: 'scrollingContainer',
                        arrow: true,
                        flip: false,
                        placement: 'top-start',
                        animation: 'shift-away-subtle',
                      }}>
                        <Button class="buy-gift green" primary disabled>
                            {$_('NotEligible')}
                        </Button>
                    </div>
                {/if}
            </div>
        {/each}
    </div>
{/if}

{#if !playerSearched}
    <hr class="recipient-info-hr">
    <div class="recipient-info">
        <picture class="info-icon" use:tippy={{
                        content: $_('GiftFriendRecipientInfoIcon').replace(/\n/g, "<br>"),
                        theme: 'shop',
                        boundary: 'scrollingContainer',
                        arrow: true,
                        flip: false,
                        placement: 'top-start',
                        animation: 'shift-away-subtle',
                        allowHTML: true,
                      }}>
            <source srcset={infoIconUrl[0]} type="image/webp">
            <source srcset={infoIconUrl[1]} type="image/jpg">
            <img alt="Info Icon" src={infoIconUrl[0]}>
        </picture>
        <span use:tippy={{
                        content: $_('GiftFriendHowItWorksTooltip').replace(/\n/g, "<br>"),
                        theme: 'shop',
                        boundary: 'scrollingContainer',
                        arrow: true,
                        flip: false,
                        placement: 'top-start',
                        animation: 'shift-away-subtle',
                        allowHTML: true,
                      }}>{$_("GiftFriendHowItWorks")}</span>
        <span use:tippy={{
                        content: $_('GiftFriendFindingPlayerIdTooltip').replace(/\n/g, "<br>"),
                        theme: 'shop',
                        boundary: 'scrollingContainer',
                        arrow: true,
                        flip: false,
                        placement: 'top-start',
                        animation: 'shift-away-subtle',
                        allowHTML: true,
                      }}>{$_("GiftFriendFindingPlayerId")}</span>
    </div>
{/if}

<style lang="scss">
  :global {

    .player-search-box {
      display: flex;
      align-items: center;
      padding: 10px 20px 10px 10px;
      border-radius: 10px;
      background-color: #ffeeb3;
      box-shadow: inset 0 0 76px 0 rgba(255, 226, 91, 0.47);
      width: 867px;
      position: relative;
      transition: transform 0.15s ease, background-color 0.15s ease;
      max-width: 95%;

      &.default input {
        font-weight: bold;
      }
    }

    .player-search-box input {
      flex-grow: 1;
      border: none transparent !important;
      box-shadow: none !important;
      caret-color: #3ca1ff;

      &::placeholder {
        color: rgba(57, 25, 14, 0.5);
        font-family: var(--font-family-noto-sans);
        font-size: clamp(1rem, 4vw, 14px);
      }
    }

    .player-search-box .fa-search {
      margin-left: 10px;
    }

    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      display: none;
    }

    input[type="search"]::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }

    input[type="search"]::-ms-reveal {
      display: none;
      height: 0;
      width: 0;
    }

    .results {
      display: none;
      width: 867px;
      background-color: #fff0be;
      box-shadow: 0 18px 27px 0 rgba(28, 18, 11, 0.27), inset 0 1px 0 0 rgba(0, 0, 0, 0.08);
      position: absolute;
      top: 55px;
      left: 0;
      max-width: 95%;

      &.no-auto-complete {
        position: relative;
        top: 0;
        left: 0;
        background-color: transparent;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 300px;
        gap: 5px;

        .result {
          border-radius: 5px;
          background-color: #1e2734;
          box-shadow: 0 18px 27px 0 rgba(28, 18, 11, 0.27), inset 0 1px 0 0 rgba(0, 0, 0, 0.08);
          max-width: 100%;
          font-size: clamp(1rem, 4vw, 12px);

          &:hover {
            background-color: #1e2734;
            filter: brightness(1.1);
          }

          &.faded {
            background-color: rgba(30, 39, 52, 0.5);

            .player-details-wrapper {
              opacity: 0.5;
            }

            &:hover {
              cursor: default;
              background-color: rgba(30, 39, 52, 0.5);
            }
          }

          .player-details {
            max-width: 50%;
            overflow: hidden;

            .player-details-top {
              .player-clan {
                color: rgba(150, 227, 255, 0.6) !important;
              }

              .player-name {
                color: rgba(150, 227, 255, 0.8) !important;
              }
            }

            .player-details-bottom {
              .player-power {
                color: #9be2e0 !important;
                font-size: clamp(1.2rem, 4vw, 12px);
              }
            }
          }
        }
      }
    }

    .results.show {
      display: block;
    }

    .results .result {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 867px;
      height: 70px;
      position: relative;
      max-width: 95%;

      .highlight {
        font-weight: bold;
      }

      &:hover {
        background-color: rgb(244, 191, 48, 0.2);
      }

      &.faded {
        .player-details-wrapper {
          opacity: 0.5;
        }

        &:hover {
          cursor: default;
          background-color: initial;
        }
      }

      .player-details-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 830px;
        max-width: 95%;
        gap: 2%;

        .player-details {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          line-height: 1.2;
          max-width: 50%;
          overflow: hidden;

          .player-details-top {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;

            .player-clan {
              font-family: var(--font-family-noto-sans);
              color: rgb(175, 148, 102);
            }

            .player-name {
              font-family: var(--font-family-noto-sans);
              color: rgb(57, 25, 14);
            }
          }

          .player-details-bottom {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .player-power {
              color: rgb(175, 148, 102);
            }
          }
        }
      }

      .buy-gift-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        right: 5%;
        bottom: 17px;
        width: 190px;
        height: 46px;
        max-width: 25%;

        button {
          font-size: clamp(0.6rem, 4vw, 14px);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }


    .player-search-box.default {
      .searchIcon {
        margin-left: 20%;
      }

      .circleXmark-wrapper, .searchIcon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .circleXmark-wrapper .circleXmark {
        opacity: 0.5;
        cursor: pointer;
        transition: transform 0.25s ease, opacity 0.25s ease;

        &:hover {
          opacity: 1;
        }
      }

      .searchIcon-wrapper .searchIcon {
        cursor: pointer;
      }
    }
  }

  .results-for {
    color: rgba(150, 227, 255, 0.6);
    font-family: var(--font-family-noto-sans);
    width: 830px;
    max-width: 90%;
    margin-top: 3%;
    font-size: clamp(1.2rem, 4vw, 14px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    i {
      color: #9be2e0;
      font-weight: bold;
    }
  }

  .results-for-hr {
    background-color: rgb(255, 255, 255, 0.15);
    width: 867px;
    max-width: 95%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  hr.recipient-info-hr {
    background-color: rgb(255, 255, 255, 0.15);
    width: 828px;
    height: 1px;
    margin-top: 50px;
    max-width: 85%;
  }

  .recipient-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: clamp(1.2rem, 4vw, 20px);
    color: rgb(255, 212, 103);
    text-shadow: 1px 1.732px 0 rgba(0, 0, 0, 0.4);
    margin-bottom: 300px;
    cursor: default;
    max-width: 90%;

    .info-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>