<script>
    import {onMount} from 'svelte';
    import {
        adventCalendarEvent,
        castleLevel,
        luckyDrawEvent,
        rechargeRewardsEvent,
        storePacks,
        storeSection,
        vipLevel
    } from "../stores.js";
    import {_} from "svelte-i18n";
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faLock} from "@fortawesome/free-solid-svg-icons";
    import Ribbon from "./Ribbon.svelte";
    import {Tab, Tabs} from "svelte-chota";

    let tabsList;
    let dragging = false;

    // Reactive statement to manage icons visibility
    $: manageIcons();


    function manageIcons() {
        if (!tabsList) return;

        const leftArrowContainer = tabsList.parentElement.querySelector('.left-arrow');
        const rightArrowContainer = tabsList.parentElement.querySelector('.right-arrow');

        if (tabsList.scrollLeft >= 20) {
            leftArrowContainer.classList.add('active');
        } else {
            leftArrowContainer.classList.remove('active');
        }

        let maxScrollValue = tabsList.scrollWidth - tabsList.clientWidth - 20;

        if (tabsList.scrollLeft >= maxScrollValue) {
            rightArrowContainer.classList.remove('active');
        } else {
            rightArrowContainer.classList.add('active');
        }
    }

    function handleArrowClick(direction) {
        const scrollAmount = 200;
        tabsList.scrollLeft += direction === 'right' ? scrollAmount : -scrollAmount;
    }

    function handleMouseDown() {
        dragging = true;
    }

    function handleMouseMove(e) {
        if (!dragging) return;
        tabsList.classList.add('dragging');
        tabsList.scrollLeft -= e.movementX;
    }

    function handleMouseUp() {
        dragging = false;
        tabsList.classList.remove('dragging');
    }

    onMount(() => {
        manageIcons();
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    });

    function shouldDisplayTab(section) {
        // Find the first featured pack in the entire array
        const firstFeaturedPack = $storePacks.find(pack => pack.isFeatured === true);

        // Section-specific filtering
        let packs;
        if (section === 'promos') {
            packs = $storePacks.filter(pack => pack.storeSection === section && !pack.luckyDraw);
        } else {
            packs = $storePacks.filter(pack => pack.storeSection === section);
        }

        // If there's only one pack in the current section
        if (packs.length === 1) {
            // Check if this pack is the first featured pack in the entire array
            const isOnlyPackFeatured = packs[0].isFeatured === true && packs[0] === firstFeaturedPack;
            // If the only pack in this section is the first featured pack, don't display the tab
            return !isOnlyPackFeatured;
        }

        // For all other cases, display the tab if the section has at least one pack
        return packs.length > 0;
    }
</script>

<div class="scrollable-tabs-container">
    <Tabs full bind:active={$storeSection}>
        <div class="left-arrow" on:click={() => handleArrowClick('left')}>
            <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 arrow"
            >
                <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 19.5L8.25 12l7.5-7.5"
                />
            </svg>
        </div>
        <ul bind:this={tabsList} on:scroll={manageIcons} on:mousedown={handleMouseDown} on:mousemove={handleMouseMove}>
            {#if Object.keys($adventCalendarEvent).length !== 0}
                <Tab tabid="adventCalendar">
                    {$_('AdventCalendar')}
                    {#if localStorage.getItem("adventCalendarSeen") !== "true"}
                        <Ribbon>{$_('New')}</Ribbon>
                    {/if}
                </Tab>
            {/if}
            {#if $storePacks.filter((pack) => pack.isFeatured === true).length > 0}
                <Tab tabid="featured">{$_('NewShopTabFeatured')}</Tab>
            {/if}
            {#if shouldDisplayTab('beginnerPacks') && $storePacks.filter((pack) => pack.storeSection === 'beginnerPacks').length > 0}
                <Tab tabid="beginnerPacks">{$_('NewShopTabBeginnerDeals')}</Tab>
            {/if}
            {#if shouldDisplayTab('welcomeBackPacks') && $storePacks.filter((pack) => pack.storeSection === 'welcomeBackPacks').length > 0}
                <Tab tabid="welcomeBackPacks">{$_('NewShopTabWelcomeBack')}</Tab>
            {/if}
            {#if shouldDisplayTab('promos') && $storePacks.filter((pack) => pack.storeSection === 'promos').length > 0}
                <Tab tabid="promos">{$_('NewShopTabPromos')}</Tab>
            {/if}
            {#if shouldDisplayTab('dailyDeals') && $storePacks.filter((pack) => pack.storeSection === 'dailyDeals').length > 0}
                <Tab tabid="dailyDeals">{$_('NewShopTabDailyDeals')}</Tab>
            {/if}
            <!--{#if $storePacks.filter((pack) => pack.storeSection === 'resourceTrove').length > 0}-->
            <!--    <Tab tabid="resourceTrove">{$_('NewShopTabAdvancementRewards')}</Tab>-->
            <!--{/if}-->
            {#if shouldDisplayTab('midweekMadness') && $storePacks.filter((pack) => pack.storeSection === 'midweekMadness').length > 0}
                <Tab tabid="midweekMadness">{$_('NewShopTabMidweekMadness')}</Tab>
            {/if}
            {#if shouldDisplayTab('webshopExclusive') && $storePacks.filter((pack) => pack.storeSection === 'webshopExclusive').length > 0}
                <Tab tabid="webshopExclusive">{$_('NewShopTabWebshopExclusive')}</Tab>
            {/if}
            {#if Object.keys($rechargeRewardsEvent).length !== 0}
                <Tab tabid="goldRush">{$_('NewShopTabEventRecharge')}</Tab>
            {/if}
            {#if Object.keys($luckyDrawEvent).length !== 0 && $storePacks.filter((pack) => pack.luckyDraw).length > 0}
                <Tab tabid="luckyDraw">
                    {$_('LuckyDraw')}
                </Tab>
            {/if}
            {#if $storePacks.filter((pack) => {
                // Check if the pack is in the 'vip' section
                if (pack.storeSection === 'vip') {
                    // Perform the regex match
                    const match = pack.bundleName.match(/lvl(\d+)pack/);
                    // Ensure match is not null and the matched VIP level equals $vipLevel
                    // Note: Ensure $vipLevel is a string for strict equality check or convert both to the same type
                    return match && match[1] <= $vipLevel;
                }
                return false; // Return false if not in 'vip' section or no regex match
            }).length > 0}
                <Tab tabid="vip">{$_('NewShopTabVIP')}</Tab>
            {/if}
            <Tab tabid="goldAndResources">{$_('NewShopTabGoldAndResources')}</Tab>
            <Tab tabid="giftFriend">
                {#if $castleLevel < 15}
                    <Fa icon={faLock} class="lock-icon" fw primaryColor="#7f584b" size="1x"/>
                {/if}
                {$_('GiftFriend')}
            </Tab>
            <Tab tabid="redeemCode">{$_('RedeemCode')}</Tab>
        </ul>
        <div class="right-arrow" on:click={() => handleArrowClick('right')}>
            <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 arrow"
            >
                <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
            </svg>
        </div>
    </Tabs>
</div>

<style lang="scss">
  :global {
    .scrollable-tabs-container {
      overflow: hidden;
      position: relative;
      height: 50px;
      width: 100%;
      top: 25px;
    }

    .scrollable-tabs-container .arrow {
      width: 24px;
      height: 24px;
      padding: 8px;
      cursor: pointer;
      color: #fff;
      border-radius: 50%;
      pointer-events: auto;
    }

    .scrollable-tabs-container ul {
      width: 100%;
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-behavior: smooth;
    }

    .scrollable-tabs-container ul.dragging a {
      pointer-events: none;
    }

    .scrollable-tabs-container ul.dragging {
      scroll-behavior: auto;
    }

    .scrollable-tabs-container ul::-webkit-scrollbar {
      display: none;
    }

    .scrollable-tabs-container a {
      color: #fff;
      text-decoration: none;
      background: #333;
      padding: 4px 24px;
      display: inline-block;
      border-radius: 4px;
      user-select: none;
      white-space: nowrap;
    }

    .scrollable-tabs-container a.active {
      background: #fff;
      color: #000;
    }

    .scrollable-tabs-container .right-arrow,
    .scrollable-tabs-container .left-arrow {
      position: absolute;
      height: 100%;
      width: 100px;
      top: 0;
      display: none;
      align-items: center;
      padding: 0 10px;
      pointer-events: none;
    }

    .scrollable-tabs-container .right-arrow.active,
    .scrollable-tabs-container .left-arrow.active {
      display: flex;
    }

    .scrollable-tabs-container .right-arrow {
      right: 0;
      background: linear-gradient(to left, #000 30%, transparent) !important;
      justify-content: flex-end;
      z-index: 1;
    }

    .scrollable-tabs-container .left-arrow {
      background: linear-gradient(to right, #000 30%, transparent) !important;
      z-index: 1;
      left: 0;
    }

    .scrollable-tabs-container .arrow:hover {
      background: #333;
    }
  }
</style>